<template>
  <div class="appointmentList-view">
    <el-form ref="ruleForm" class="ruleForm" label-width="100px">
      <el-form-item label="姓名">
        <div class="flex-a-c">
          <el-select v-model="formdata.empId" :filterable="true" :disabled="type == 'edit' ? true : false"
            style="width: 240px;margin-bottom:20px" placeholder="请选择">
            <el-option v-for="(item, index) in memberList" :key="index" :label="item.name" :value="item.empId" />
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="负责部门">
        <div class="flex-a-c">
          <el-select v-model="formdata.departmentId" style="width: 240px;" placeholder="请选择">
            <el-option v-for="(item, index) in departmentList" :key="index" :label="item.name"
              :value="item.departmentId" />
          </el-select>
          <div class="flex-a-c ml20" style="color: #8d97ad; font-size: 14px" v-if="departmentList.length == 0">
            暂无部门
            <el-button class="v-primary ml10" type="primary" @click="toSectorSetupList">去设置</el-button>
          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item label="状态">
        <el-switch
          v-model="formdata.empStatus">
        </el-switch>
      </el-form-item> -->
      <el-form-item label="权限">
        <el-tree :data="treeData" ref="tree" :props="defaultProps" show-checkbox accordion @check="currentChange"
          node-key="id"></el-tree>
      </el-form-item>
      <el-form-item>
        <p class="ml10">未选择任何权限即默认此管理员为普通员工权限</p>
        <el-button class="v-primary ml10" type="primary" @click="saveManager">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import {
  queryPageEmployee,
  queryPageDepartment,
  addManager,
  queryEmployee,
  updateManager
} from "@/api/companyManage.js";
import userRoot from '@/utils/root'

export default {
  name: "appointmentList",
  data() {
    return {
      memberList: [],
      departmentList: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      formdata: {
        empId: '',
        departmentId: '',
        empStatus: false,
        routers: userRoot.defaultRouters,
        userType: 1  //用户类型 0 默认员工 1管理员 2 boss
      },
      type: 'add'
    };
  },
  mounted() {
    this.getPageEmployee()
    this.getPageDepartment()
    this.treeData = userRoot.userRoot
    if (this.$route.query.id) {
      this.type = 'edit'
      this.queryEmployee()
    }
    this.$refs.tree.setCheckedKeys(this.formdata.routers)
  },
  methods: {
    ...mapMutations(['setRouterArr']),
    //获取企业成员
    getPageEmployee() {
      let data = {
        pageSize: 999,
      };
      queryPageEmployee(data)
        .then((res) => {
          this.memberList = res.data.pageInfo.list || [];
        })
        .catch((err) => {
          this.departmentList = [];
          console.log(err);
        });
    },
    //请求部门信息
    getPageDepartment() {
      let data = {
        pageSize: 999,
      };
      queryPageDepartment(data)
        .then((res) => {
          this.departmentList = res.data.pageInfo.list || [];
        })
        .catch((err) => {
          this.departmentList = [];
          console.log(err);
        });
    },
    //保存管理员
    async saveManager() {
      if (!this.formdata.empId) {
        this.$message.error('请确认选择管理员')
      }
      let result = ''
      if (this.type == 'add') {
        result = await addManager(this.formdata)
      } else {
        result = await updateManager(this.formdata)
      }
      if (result && result.code == 200) {
        this.$message.success(this.type == 'add' ? '保存成功' : '修改成功')
        this.$router.go(-1)
      } else {
        this.$message.error(result && result.message ? result.message : '操作失败')
      }
    },
    //查询管理员详情
    queryEmployee() {
      let data = {
        empId: this.$route.query.id
      }
      queryEmployee(data)
        .then(res => {
          this.formdata.empId = res.data.empId
          this.formdata.departmentId = res.data.departmentId
          this.formdata.empStatus = res.data.empStatus
          if (res.data.routers && res.data.routers !== 'null') {
            this.formdata.routers = JSON.parse(res.data.routers)
          } else {
            this.formdata.routers = this.$defaultRouters
          }
          this.formdata.routers = this.filtration(this.treeData)
          this.formdata.userType = res.data.userType
          this.$refs.tree.setCheckedKeys(this.formdata.routers)
        })
    },
    currentChange(a, b) {
      this.formdata.routers = b.checkedKeys.concat(...b.halfCheckedKeys)
    },
    filtration(treeData) {
      for (let index = 0; index < treeData.length; index++) {
        let data = treeData[index]
        if (data.children) {
          let index = this.formdata.routers.findIndex(item => item == data.id)
          if (index !== -1) {
            this.formdata.routers.splice(index, 1)
          }
          this.filtration(data.children)
        }
      }
      return this.formdata.routers
    },
    toSectorSetupList() {
      this.$router.push('/sectorSetup')
    }
  },
};
</script>
<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}

.member-box {
  padding: 5px 10px;
  background: #51CDCB;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 14px;
  color: #ffffff;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 3px;
}

.more-box {
  padding: 5px 10px;
  background: #FD634E;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 14px;
  color: #ffffff;
  border-radius: 3px;
}

.member-scroll {
  max-height: 300px;
  overflow-y: auto;
}
</style>